//import { MDBBtn } from "mdb-react-ui-kit";
import React from "react";
import styles from '../assets/scss/modules/mainpage.module.scss';
import ebike from "../assets/media/images/E-Bikebörse Logo.png";
import bikeparts from "../assets/media/images/bikeParts.png";
import booking from "../assets/media/images/ClickNRide.png";
import logo from '../assets/media/images/Logo_Bergfrei_250px-180w.png';

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
	MDBNav,
	MDBFormInline,
	MDBIcon,
	MDBView
} from 'mdb-react-ui-kit';
class MainPage extends React.Component {
    render() {
        return (
					<>

					<div className={styles.bodywrapper}>
					<MDBContainer fluid className="text-center">
					<MDBRow center>
					<MDBCol md="4">
					</MDBCol>
					<MDBCol md="4">
						<MDBRow center>
							<span className="title-text pt-5">Bergfrei Kiosk</span>
						</MDBRow>
						<MDBRow center className="pt-5">
							<span className="normal-text">Sie haben die Möglichkeit, den Besitzer des Geschäfts zu erreichen</span>
						</MDBRow>
						<MDBRow center className="py-5" style={{height: "30vh"}}>
						<a className="call-btn" href="/page/call-the-boss"><span className="call-btn-text">Call the Boss</span></a>
						</MDBRow>
						<MDBRow className="pt-5">
							<MDBCol md="4">
							<a className="ebike-btn" href="/page/ebikeboerse"><img className="ebike-icon" src={ebike}/></a><br/>
							</MDBCol>
							<MDBCol md="4">
							<a className="bikeparts-btn" href="/page/interb2bike"><img className="bikeparts-icon" src={bikeparts}/></a><br/>
							</MDBCol>
							<MDBCol md="4">
							<a className="booking-btn" href="/page/booking"><img className="booking-icon" src={booking}/></a><br/>
							</MDBCol>
						</MDBRow>
					</MDBCol>
					<MDBCol md="4">
					</MDBCol>




					</MDBRow>
</MDBContainer>
				  </div>

					<div className="footer d-flex align-items-center justify-content-center">
						<img className="logo-icon" src={logo}/>  Bergfrei 2022
					</div>
					</>
        );
    }
}

export default MainPage;
