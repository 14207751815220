import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainPage from './pages/MainPage';
import WebsiteIncluder from './WebsiteIncluder';
import CallTheBossPage from './pages/CallTheBossPage';
import BackToHome from './BackToHome';

function App() {
  const homepage = 'https://www.telex.hu';
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<MainPage/>} />
      <Route path="/page" element={<BackToHome/>}>
        <Route path="/page/ebikeboerse" element={<WebsiteIncluder homepage="https://e-bikeboerse.de"/>} />
        <Route path="/page/interb2bike" element={<WebsiteIncluder homepage="https://www.interb2bike.com"/>} />
        <Route path="/page/booking" element={<WebsiteIncluder homepage="https://bergfrei-clicknride.com"/>} />
        <Route path="/page/call-the-boss" element={<CallTheBossPage/>} />
      </Route>
    </Routes>
  </BrowserRouter>
  );
}

/*
    <div style={{height: '100vh'}}>
      <div style={{ height: '5vh'}}><a href="">Home</a></div>
      <iframe src={homepage} width="100%" style={{height: '94vh'}}></iframe>
    </div>
*/
export default App;
